





































































import Vue from "vue";
import { Action } from "vuex-class";
import { Component } from "vue-property-decorator";

import api from "@/core/utils/api";

export interface DeviceRequestMeta {
  deviceCode: string;
  expiresIn: number;
  interval: number;
  userCode: string;
  verificationUri: string;
}

@Component
export default class RegisterDevice extends Vue {
  @Action("displaySnackbar") displaySnackbar!: (s: string) => void;
  @Action("clearEverything") clearEverything!: (reroute: boolean) => void;
  @Action("profile/loginByDeviceHandshake") loginByDeviceHandshake!: (
    data: any,
  ) => Promise<void>;

  error: any = null;
  loading = false;
  done = false;
  userCode: string | undefined = undefined;
  deviceCode: string | undefined = undefined;
  url = window.location.origin;

  userCodeFormatted = (userCode: string) => {
    if (userCode)
      return (
        userCode.toString().substring(0, 3) +
        " " +
        userCode.toString().substring(3)
      );

    return "- - - - - -";
  };

  async requestDevice() {
    const { clientId } = this.$route.query;
    if (!clientId) {
      const msg = this.$t("registerDeviceSection.error").toString();
      this.displaySnackbar(msg);
      this.$router.push("/auth/login");
      return;
    }
    this.loading = true;
    this.userCode = "000000";
    try {
      const end = "/api/Account/RequestDeviceCode";
      const data = {};
      const res = ((await api
        .post(end, data, {
          headers: {
            clientId: clientId,
          },
        })
        .catch(err => {
          const msg = this.$t("registerDeviceSection.error").toString();
          this.displaySnackbar(msg);
          console.error(err);
        })) as unknown) as DeviceRequestMeta;

      this.userCode = res.userCode;
      this.deviceCode = res.deviceCode;
      this.done = true;
    } catch (error) {
      this.error =
        error || this.$t("registerDeviceSection.couldNotGetCode").toString();
      const msg = this.$t("registerDeviceSection.couldNotGetCode").toString();
      this.displaySnackbar(msg);
    }
    this.loading = false;
  }

  async signupDevice() {
    const { clientId } = this.$route.query;
    if (!clientId) {
      const msg = this.$t("registerDeviceSection.error").toString();
      this.displaySnackbar(msg);
      this.$router.push("/auth/login");
      return;
    }
    this.loading = true;

    this.clearEverything(false);
    await this.loginByDeviceHandshake({
      deviceCode: this.deviceCode,
      clientId,
    });

    this.loading = false;
  }

  created() {
    this.requestDevice();
  }
}
